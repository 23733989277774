import request from '@/utils/request';
//zml消息列表（分页）
export function MsgList(data) {
  return request({
    url: '/clientserver.php/Msg/index',
    method: 'post',
    data: data
  });
}
//设置消息已读
export function MsgUpdate(data) {
  return request({
    url: '/clientserver.php/Msg/update',
    method: 'post',
    data: data
  });
}
//人员级别配置-列表
export function UserLevelConfig(data) {
  return request({
    url: '/clientserver.php/UserLevelConfig/index',
    method: 'post',
    data: data
  });
}
//权重配置-列表
export function LevelConfig(data) {
  return request({
    url: '/clientserver.php/LevelConfig/index',
    method: 'post',
    data: data
  });
}
//人员级别配置-修改
export function configUpdate(data) {
  return request({
    url: '/clientserver.php/UserLevelConfig/update',
    method: 'post',
    data: data
  });
}
//数据接收配置-列表
export function ReceiveConfig(data) {
  return request({
    url: '/clientserver.php/ReceiveConfig/index',
    method: 'post',
    data: data
  });
}
//数据接收配置-选中列表
export function getCheckedByChooseId(data) {
  return request({
    url: '/clientserver.php/ReceiveConfig/getCheckedByChooseId',
    method: 'post',
    data: data
  });
}
//数据接收配置/编辑详情
export function ReceiveConfigRead(data) {
  return request({
    url: '/clientserver.php/ReceiveConfig/read',
    method: 'post',
    data: data
  });
}
//数据接收选择人员
export function getStructrueAndUser(data) {
  return request({
    url: '/clientserver.php/ReceiveConfig/getStructrueAndUser',
    method: 'post',
    data: data
  });
}
//数据接收配置、编辑
export function ReceiveConfigUpdate(data) {
  return request({
    url: '/clientserver.php/ReceiveConfig/update',
    method: 'post',
    data: data
  });
}
//数据接收人员详情
export function ReceiveConfigDetail(data) {
  return request({
    url: '/clientserver.php/ReceiveConfig/detail',
    method: 'post',
    data: data
  });
}