var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "app-container Staffing" }, [
    _vm.course != 1
      ? _c(
          "div",
          { staticStyle: { width: "100%" } },
          [
            _c(
              "el-header",
              { staticStyle: { height: "auto" } },
              [
                _c(
                  "el-row",
                  { staticStyle: { height: "40px" }, attrs: { gutter: 24 } },
                  [_c("Head", { attrs: { name: _vm.titles } })],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-row",
                  { attrs: { gutter: 24 } },
                  [
                    _c("el-col", { attrs: { span: 24 } }, [
                      _c(
                        "div",
                        {
                          staticClass: "source",
                          staticStyle: { "padding-top": "20px", float: "left" },
                        },
                        [
                          _c("span", { staticClass: "nameTitle" }, [
                            _vm._v("推广平台："),
                          ]),
                          _vm._v(" "),
                          _c("el-cascader", {
                            attrs: {
                              size: "small",
                              clearable: "",
                              "collapse-tags": "",
                              options: _vm.extension,
                              props: { value: "id", label: "name" },
                            },
                            model: {
                              value: _vm.extensionVal,
                              callback: function ($$v) {
                                _vm.extensionVal = $$v
                              },
                              expression: "extensionVal",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "source",
                          staticStyle: {
                            "padding-top": "20px",
                            float: "left",
                            "margin-left": "20px",
                          },
                        },
                        [
                          _c("span", { staticClass: "nameTitle" }, [
                            _vm._v("数据对应花名："),
                          ]),
                          _vm._v(" "),
                          _c(
                            "el-select",
                            {
                              staticStyle: {
                                width: "180px",
                                "margin-right": "0",
                              },
                              attrs: {
                                size: "small",
                                filterable: "",
                                placeholder: "请搜索花名",
                                clearable: "",
                              },
                              model: {
                                value: _vm.source_client_id,
                                callback: function ($$v) {
                                  _vm.source_client_id = $$v
                                },
                                expression: "source_client_id",
                              },
                            },
                            _vm._l(_vm.flower_Array, function (item) {
                              return _c("el-option", {
                                key: item.id,
                                attrs: {
                                  label: item.flower_name,
                                  value: item.id,
                                },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "source",
                          staticStyle: {
                            "padding-top": "20px",
                            float: "left",
                            "margin-left": "20px",
                          },
                        },
                        [
                          _c("span", { staticClass: "nameTitle" }, [
                            _vm._v("数据创建人："),
                          ]),
                          _vm._v(" "),
                          _c(
                            "el-select",
                            {
                              ref: "clearValue",
                              staticStyle: { "margin-left": "20px" },
                              attrs: {
                                filterable: "",
                                remote: "",
                                clearable: "",
                                size: "small",
                                "collapse-tags": "",
                                "reserve-keyword": "",
                                "remote-method": _vm.ReceiverMethod,
                                placeholder: "请选择数据创建人",
                              },
                              model: {
                                value: _vm.Receiver,
                                callback: function ($$v) {
                                  _vm.Receiver = $$v
                                },
                                expression: "Receiver",
                              },
                            },
                            _vm._l(_vm.Receiver_Arr, function (item) {
                              return _c("el-option", {
                                key: item.id,
                                attrs: { label: item.realname, value: item.id },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "source",
                          staticStyle: {
                            "padding-top": "20px",
                            float: "left",
                            "margin-left": "20px",
                          },
                        },
                        [
                          _c("el-input", {
                            staticClass: "input-with-select",
                            attrs: {
                              clearable: "",
                              placeholder: "推广项目名称搜索",
                              size: "small",
                            },
                            model: {
                              value: _vm.search_realname,
                              callback: function ($$v) {
                                _vm.search_realname = $$v
                              },
                              expression: "search_realname",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "source",
                          staticStyle: {
                            "padding-top": "20px",
                            float: "left",
                            "margin-left": "20px",
                          },
                        },
                        [
                          _c(
                            "el-button",
                            {
                              attrs: { size: "small", type: "primary" },
                              on: { click: _vm.queryClick },
                            },
                            [_vm._v("查询")]
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "source",
                          staticStyle: {
                            "padding-top": "20px",
                            float: "left",
                            "margin-left": "20px",
                          },
                        },
                        [
                          _c(
                            "el-button",
                            {
                              attrs: { size: "small", type: "primary" },
                              on: { click: _vm.batch },
                            },
                            [_vm._v("批量分配人员")]
                          ),
                        ],
                        1
                      ),
                    ]),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "el-main",
              { staticStyle: { width: "100%", "margin-bottom": "20px" } },
              [
                _c(
                  "el-table",
                  {
                    directives: [
                      {
                        name: "loading",
                        rawName: "v-loading",
                        value: _vm.listLoading,
                        expression: "listLoading",
                      },
                    ],
                    attrs: {
                      data: _vm.tableData,
                      "row-key": "project_id",
                      border: "",
                    },
                    on: { "selection-change": _vm.handleSelectionChange },
                  },
                  [
                    _c("el-table-column", {
                      attrs: { type: "selection", align: "center" },
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        prop: "project_name",
                        align: "center",
                        label: "推广项目名称",
                      },
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        prop: "platform_name",
                        align: "center",
                        label: "推广平台",
                      },
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        prop: "adsense_id",
                        align: "center",
                        label: "落地页ID",
                      },
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      directives: [
                        {
                          name: "permission",
                          rawName: "v-permission",
                          value: ["人员详情"],
                          expression: "['人员详情']",
                        },
                      ],
                      attrs: {
                        prop: "everyday_max",
                        align: "center",
                        label: "人员详情",
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                scope.row.is_show_detail == 1
                                  ? _c(
                                      "el-button",
                                      {
                                        attrs: { type: "text", size: "mini" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.detailsClickSha(
                                              scope.row.project_id
                                            )
                                          },
                                        },
                                      },
                                      [_vm._v("人员详情")]
                                    )
                                  : _vm._e(),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        3476345465
                      ),
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        prop: "flower_name",
                        align: "center",
                        label: "数据对应花名",
                      },
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        prop: "create_name",
                        align: "center",
                        label: "数据创建人",
                      },
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      directives: [
                        {
                          name: "permission",
                          rawName: "v-permission",
                          value: ["编辑/分配"],
                          expression: "['编辑/分配']",
                        },
                      ],
                      attrs: { label: "操作", width: "380", align: "center" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                scope.row.operation_type == 0
                                  ? _c(
                                      "el-button",
                                      {
                                        staticStyle: { color: "red" },
                                        attrs: { type: "text", size: "mini" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.configureClick(
                                              1,
                                              scope.row.project_id
                                            )
                                          },
                                        },
                                      },
                                      [_vm._v("配置")]
                                    )
                                  : _c(
                                      "el-button",
                                      {
                                        attrs: { type: "text", size: "mini" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.configureClick(
                                              2,
                                              scope.row.project_id
                                            )
                                          },
                                        },
                                      },
                                      [_vm._v("编辑")]
                                    ),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        3710926258
                      ),
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("pagination", {
                  attrs: {
                    "current-page": _vm.query.page,
                    totalPage: _vm.query.total,
                    pageSize: _vm.query.pagesize,
                  },
                  on: {
                    handleSizeChange: _vm.handleSizeChange,
                    handleCurrentChange: _vm.handleCurrentChange,
                  },
                }),
                _vm._v(" "),
                _c(
                  "el-dialog",
                  {
                    staticClass: "big-dialogs",
                    attrs: {
                      title: _vm.grade,
                      visible: _vm.configure,
                      "close-on-click-modal": true,
                    },
                    on: {
                      "update:visible": function ($event) {
                        _vm.configure = $event
                      },
                    },
                  },
                  [
                    _c(
                      "el-form",
                      {
                        staticStyle: { width: "400px", margin: "0 auto" },
                        attrs: {
                          "label-position": "right",
                          "label-width": "130px",
                        },
                      },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "推广项目名称:" } },
                          [_c("span", [_vm._v(_vm._s(_vm.PromotionName))])]
                        ),
                        _vm._v(" "),
                        _c("el-form-item", { attrs: { label: "推广平台:" } }, [
                          _c("span", [_vm._v(_vm._s(_vm.PromotionId))]),
                        ]),
                        _vm._v(" "),
                        _c("el-form-item", { attrs: { label: "落地页ID:" } }, [
                          _c("span", [_vm._v(_vm._s(_vm.LandingId))]),
                        ]),
                        _vm._v(" "),
                        _c(
                          "el-form-item",
                          { attrs: { label: "选择人员:" } },
                          [
                            _c("el-cascader", {
                              staticStyle: { width: "100%" },
                              attrs: {
                                placeholder: "选择部门",
                                clearable: "",
                                options: _vm.personnelArr,
                                filterable: "",
                                props: {
                                  value: "id",
                                  label: "name",
                                  multiple: true,
                                },
                              },
                              on: { change: _vm.personnelClick },
                              model: {
                                value: _vm.personnel,
                                callback: function ($$v) {
                                  _vm.personnel = $$v
                                },
                                expression: "personnel",
                              },
                            }),
                            _vm._v(" "),
                            _c("br"),
                            _vm._v(" "),
                            _c(
                              "p",
                              {
                                staticStyle: {
                                  color: "#999",
                                  "margin-top": "0px",
                                },
                              },
                              [
                                _c("i", {
                                  staticClass: "iconfont icon-tixing",
                                }),
                                _vm._v(
                                  " 配置修改后最晚第二天生效\n                        "
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "dialog-footer",
                        staticStyle: { "padding-top": "50px" },
                        attrs: { slot: "footer" },
                        slot: "footer",
                      },
                      [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "primary", disabled: _vm.prohibit },
                            on: { click: _vm.toConfigure },
                          },
                          [_vm._v("确认")]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-dialog",
                  {
                    staticClass: "big-dialogs",
                    attrs: { title: "数据接收人员详情", visible: _vm.details },
                    on: {
                      "update:visible": function ($event) {
                        _vm.details = $event
                      },
                    },
                  },
                  [
                    _c(
                      "p",
                      {
                        staticStyle: {
                          "text-align": "center",
                          "text-indent": "-1em",
                        },
                      },
                      [
                        _c("span", { staticStyle: { color: "#f27c2d" } }, [
                          _c("i", { staticClass: "iconfont icon-xiangmu" }),
                          _vm._v(" "),
                          _c("span", { staticStyle: { "font-size": "15px" } }, [
                            _vm._v("推广项目名称：" + _vm._s(_vm.entryName)),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c(
                          "span",
                          {
                            staticStyle: {
                              color: "#3783f0",
                              "margin-left": "35px",
                            },
                          },
                          [
                            _c("i", { staticClass: "iconfont icon-tuiguang" }),
                            _vm._v(" "),
                            _c(
                              "span",
                              { staticStyle: { "font-size": "15px" } },
                              [_vm._v("推广平台：" + _vm._s(_vm.terrace))]
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "span",
                          {
                            staticStyle: {
                              color: "#43b04a",
                              "margin-left": "35px",
                            },
                          },
                          [
                            _c("i", { staticClass: "iconfont icon-id" }),
                            _vm._v(" "),
                            _c(
                              "span",
                              { staticStyle: { "font-size": "15px" } },
                              [_vm._v("落地页ID：" + _vm._s(_vm.toGround))]
                            ),
                          ]
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-main",
                      { staticClass: "Details" },
                      [
                        _c(
                          "el-table",
                          {
                            directives: [
                              {
                                name: "loading",
                                rawName: "v-loading",
                                value: _vm.listLoading,
                                expression: "listLoading",
                              },
                            ],
                            attrs: {
                              data: _vm.DetailsPopUp,
                              "row-key": "project_id",
                              border: "",
                            },
                          },
                          [
                            _c("el-table-column", {
                              attrs: {
                                prop: "realname",
                                align: "center",
                                label: "接收主管名称",
                              },
                            }),
                            _vm._v(" "),
                            _c("el-table-column", {
                              attrs: {
                                prop: "structure_name",
                                align: "center",
                                label: "所属部门",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "dialog-footer",
                        staticStyle: { "padding-top": "50px" },
                        attrs: { slot: "footer" },
                        slot: "footer",
                      },
                      [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "primary" },
                            on: {
                              click: function ($event) {
                                _vm.details = false
                              },
                            },
                          },
                          [_vm._v("确认")]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-dialog",
                  {
                    staticClass: "big-dialogs",
                    attrs: {
                      title: "批量分配人员",
                      visible: _vm.distribution,
                      "close-on-click-modal": true,
                    },
                    on: {
                      "update:visible": function ($event) {
                        _vm.distribution = $event
                      },
                    },
                  },
                  [
                    _c(
                      "el-form",
                      {
                        staticStyle: { width: "400px", margin: "0 auto" },
                        attrs: {
                          "label-position": "right",
                          "label-width": "130px",
                        },
                      },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "选择人员:" } },
                          [
                            _c("el-cascader", {
                              staticStyle: { width: "100%" },
                              attrs: {
                                placeholder: "选择部门",
                                clearable: "",
                                options: _vm.personnelArrs,
                                filterable: "",
                                props: {
                                  value: "id",
                                  label: "name",
                                  multiple: true,
                                },
                              },
                              on: { change: _vm.personnelBatch },
                              model: {
                                value: _vm.personnels,
                                callback: function ($$v) {
                                  _vm.personnels = $$v
                                },
                                expression: "personnels",
                              },
                            }),
                            _vm._v(" "),
                            _c("br"),
                            _vm._v(" "),
                            _c(
                              "p",
                              {
                                staticStyle: {
                                  color: "#999",
                                  "margin-top": "0px",
                                },
                              },
                              [
                                _c("i", {
                                  staticClass: "iconfont icon-tixing",
                                }),
                                _vm._v(
                                  " 配置修改后最晚第二天生效\n                        "
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "dialog-footer",
                        staticStyle: { "padding-top": "50px" },
                        attrs: { slot: "footer" },
                        slot: "footer",
                      },
                      [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "primary", disabled: _vm.prohibits },
                            on: { click: _vm.toConfigures },
                          },
                          [_vm._v("确认")]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        )
      : _c(
          "div",
          {
            staticClass: "boxImg",
            staticStyle: { width: "100%", padding: "20px" },
          },
          [
            _c(
              "el-row",
              {
                staticStyle: { height: "30px", margin: "0px 0px" },
                attrs: { gutter: 24 },
              },
              [
                _c("Head", { attrs: { name: _vm.titles } }),
                _vm._v(" "),
                _c("div", {
                  directives: [
                    {
                      name: "permission",
                      rawName: "v-permission",
                      value: [""],
                      expression: "['']",
                    },
                  ],
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "el-row",
              { attrs: { gutter: 24 } },
              [
                _c(
                  "el-col",
                  { attrs: { span: 24 } },
                  [
                    _vm.tutorialEs
                      ? _c(
                          "el-button",
                          {
                            staticStyle: { float: "right" },
                            attrs: { size: "small", type: "primary" },
                            on: { click: _vm.detailsClick },
                          },
                          [_vm._v("编辑")]
                        )
                      : _vm._e(),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c("div", { domProps: { innerHTML: _vm._s(_vm.centerHtml) } }),
            _vm._v(" "),
            _c(
              "el-dialog",
              {
                attrs: {
                  width: "60%",
                  title: "编辑",
                  visible: _vm.dialogFormVisible,
                },
                on: {
                  "update:visible": function ($event) {
                    _vm.dialogFormVisible = $event
                  },
                },
              },
              [
                _c(
                  "el-form",
                  { ref: "editForm", attrs: { "label-width": "100px" } },
                  [
                    _c(
                      "el-form-item",
                      { staticClass: "is-required", attrs: { label: "简介" } },
                      [
                        _c("quill-editor", {
                          ref: "myTextEditor",
                          staticClass: "ql-container",
                          staticStyle: { height: "400px" },
                          attrs: { options: _vm.editorOption },
                          model: {
                            value: _vm.content,
                            callback: function ($$v) {
                              _vm.content = $$v
                            },
                            expression: "content",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "dialog-footer",
                    staticStyle: { "padding-top": "50px" },
                    attrs: { slot: "footer" },
                    slot: "footer",
                  },
                  [
                    _c(
                      "el-button",
                      {
                        on: {
                          click: function ($event) {
                            _vm.dialogFormVisible = false
                          },
                        },
                      },
                      [_vm._v("取消")]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-button",
                      {
                        attrs: { type: "primary", disabled: _vm.prohibit },
                        on: { click: _vm.updateData },
                      },
                      [_vm._v("确认")]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }