import "core-js/modules/es6.regexp.replace";
import "core-js/modules/es6.regexp.to-string";
import "core-js/modules/es6.regexp.split";
import "core-js/modules/web.dom.iterable";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import 'quill/dist/quill.core.css';
import 'quill/dist/quill.snow.css';
import 'quill/dist/quill.bubble.css';
import { SystemManualInfo, SystemManualEdit } from '@/api/article';
import { quillEditor } from 'vue-quill-editor';
import { getPlatformList } from '@/api/ceshi_xiugai/popularize';
import { getUserList } from '@/api/updateUserInfo/structure';
import { agentFlowerNameList } from '@/api/ceshi_xiugai/whole';
import { ReceiveConfig, ReceiveConfigRead, getStructrueAndUser, ReceiveConfigUpdate, ReceiveConfigDetail, getCheckedByChooseId } from '@/api/updateUserInfo/Msg';
import Head from '@/components/head/index';
import 'nprogress/nprogress.css'; // progress bar style
export default {
  name: 'Staffing',
  data: function data() {
    return {
      data1111: [{
        adsense_id: '12312321321',
        advert_id: 80,
        coustomer_create_id: 306,
        create_name: '陈士强',
        disabled: false,
        flower_name: '邓方',
        is_show_detail: 0,
        operation_type: 0,
        platform_name: '百度',
        project_id: 204,
        project_name: '11111111111',
        source_client_id: 5,
        structure_share: '83'
      }],
      // 系统教程
      course: this.$route.query.course,
      prohibit: false,
      prohibits: true,
      tutorialEs: false,
      dialogFormVisible: false,
      centerHtml: '',
      content: '',
      editorOption: {
        placeholder: 'Hello World'
      },
      menuId: localStorage.getItem('menuId'),
      // 系统教程
      titles: '数据接收配置',
      value1: '',
      value: '',
      checkList: [],
      searchRealname: '',
      urlPath: this.getUrlPath(),
      // iframe src 路径，
      query: {
        page: 1,
        pagesize: 10,
        total: 0 //总条数，不是总页数
      },
      extensionVal: [],
      extension: [],
      source_client_id: '',
      //花名id
      flower_Array: [],
      //花名
      Receiver: '',
      Receiver_Arr: [],
      PromotionName: '',
      PromotionId: '',
      LandingId: '',
      personnel: [],
      personnels: [],
      pids: '',
      personnelId: '',
      personnelIds: '',
      personnelArr: [],
      personnelArrs: [],
      search_realname: '',
      tableData: [],
      grade: '数据接收人员配置',
      configure: false,
      msg: '配置成功',
      num: '',
      details: false,
      listLoading: false,
      distribution: false,
      DetailsPopUp: [],
      entryName: '',
      terrace: '',
      toGround: '',
      arrarw: [{
        id: 202
      }, {
        id: 204
      }],
      assdd: [],
      talcleId: [],
      userId: []
    };
  },
  created: function created() {},
  mounted: function mounted() {
    var _this = this;
    if (this.course != 1) {
      this.getList(this.query.page, this.query.pagesize);
      this.flowerArray();
    } else {
      var jurisdiction = [];
      var tion = this.$store.getters.StatisTics;
      tion.forEach(function (item) {
        jurisdiction.push(item.title);
      });
      if (jurisdiction.indexOf('编辑教程') > -1) {
        this.tutorialEs = true;
      } else {
        this.tutorialEs = false;
      }
      SystemManualInfo({
        role_id: this.menuId
      }).then(function (res) {
        _this.centerHtml = res.data.contents;
      });
    }
  },
  components: {
    Head: Head,
    quillEditor: quillEditor
  },
  methods: {
    detailsClick: function detailsClick() {
      var _this2 = this;
      //系统教程
      SystemManualInfo({
        role_id: this.menuId
      }).then(function (res) {
        _this2.content = res.data.contents;
        _this2.dialogFormVisible = true;
      });
    },
    updateData: function updateData() {
      var _this3 = this;
      //系统教程
      this.prohibit = true;
      SystemManualEdit({
        role_id: this.menuId,
        contents: this.content
      }).then(function (res) {
        _this3.prohibit = false;
        _this3.dialogFormVisible = false;
        _this3.$notify({
          title: '成功',
          message: '编辑成功',
          type: 'success'
        });
        SystemManualInfo({
          role_id: _this3.menuId
        }).then(function (res) {
          _this3.centerHtml = res.data.contents;
        });
      });
    },
    getList: function getList(page, pagesize, tId) {
      var _this4 = this;
      //栏目列表
      this.listLoading = true;
      this.talcleId = [];
      ReceiveConfig({
        page: page,
        pagesize: pagesize,
        s_popularize_platform_id: tId,
        s_source_client_id: this.source_client_id,
        s_create_id: this.Receiver,
        s_popularize_project_name: this.search_realname
      }).then(function (respomse) {
        _this4.tableData = respomse.data.data;
        _this4.query.total = respomse.data.total;
        respomse.data.data.forEach(function (item) {
          _this4.talcleId.push(item.project_id);
        });
        _this4.listLoading = false;
      });
    },
    ClassSearchInput: function ClassSearchInput() {
      this.getList();
    },
    configureClick: function configureClick(num, id) {
      var _this5 = this;
      this.personnel = [];
      this.personnelId = '';
      if (num == 1) {
        this.msg = '配置成功';
        this.grade = '数据接收人员配置';
      } else {
        this.msg = '编辑成功';
        this.grade = '编辑数据接收人员';
      }
      getStructrueAndUser({
        project_ids: id
      }).then(function (res) {
        _this5.personnelArr = res.data;
        ReceiveConfigRead({
          popularize_project_id: id
        }).then(function (res) {
          _this5.PromotionName = res.data.project_name;
          _this5.PromotionId = res.data.platform_name;
          _this5.LandingId = res.data.adsense_id;
          if (res.data.user_ids != '') {
            _this5.personnel = res.data.user_ids.split(',').map(function (e) {
              var _this5$getDepartmentN = _this5.getDepartmentNameById(e, _this5.personnelArr),
                id = _this5$getDepartmentN.id;
              return id;
            });
          }
          _this5.personnel.forEach(function (item, index) {
            if (index > 0) {
              var share = _this5.personnel.map(function (e) {
                return e[e.length - 1];
              }).toString();
              _this5.personnelId = share == ',' ? '' : share;
            }
          });
        });
      });
      this.pids = id;
      this.num = num;
      this.configure = true;
    },
    personnelClick: function personnelClick() {
      var share = this.personnel.map(function (e) {
        return e[e.length - 1];
      }); // 部门的最后一位ID
      this.personnelId = share;
    },
    personnelBatch: function personnelBatch() {
      var share = this.personnels.map(function (e) {
        return e[e.length - 1];
      }); // 部门的最后一位ID
      this.personnelIds = share;
      if (this.personnelIds.length > 0) {
        this.prohibits = false;
      } else {
        this.prohibits = true;
      }
    },
    group: function group(array, subGroupLength) {
      var index = 0;
      var newArray = [];
      while (index < array.length) {
        newArray.push(array.slice(index, index += subGroupLength));
      }
      return newArray;
    },
    toConfigure: function toConfigure() {
      var _this6 = this;
      var aaa = this.personnel.flat();
      var parentEs = typeof this.personnelId === 'string' ? this.personnelId.split(',') : this.personnelId;
      var kd = aaa.length / parentEs.length;
      var res = this.group(aaa, kd);
      if (res != '') {
        ReceiveConfigUpdate({
          popularize_project_ids: this.pids,
          bumen_uid: res
        }).then(function (res) {
          _this6.$notify({
            title: '成功',
            message: _this6.msg,
            type: 'success'
          });
          _this6.getList(_this6.query.page, _this6.query.pagesize);
          _this6.configure = false;
        });
      } else {
        this.$notify({
          title: '警告',
          message: '请选择人员',
          type: 'warning'
        });
      }
    },
    toConfigures: function toConfigures() {
      var _this7 = this;
      var aaa = this.personnels.flat();
      var parentEs = typeof this.personnelIds === 'string' ? this.personnelIds.split(',') : this.personnelIds;
      var kd = aaa.length / parentEs.length;
      var res = this.group(aaa, kd);
      if (res != '') {
        ReceiveConfigUpdate({
          popularize_project_ids: this.userId.toString(),
          bumen_uid: res
        }).then(function (res) {
          _this7.$notify({
            title: '成功',
            message: _this7.msg,
            type: 'success'
          });
          _this7.distribution = false;
          _this7.personnels = [];
          _this7.getList(_this7.query.page, _this7.query.pagesize);
          _this7.configure = false;
        });
      } else {
        this.$notify({
          title: '警告',
          message: '请选择人员',
          type: 'warning'
        });
      }
    },
    detailsClickSha: function detailsClickSha(id) {
      var _this8 = this;
      ReceiveConfigDetail({
        popularize_project_id: id
      }).then(function (res) {
        _this8.entryName = res.data.project_name;
        _this8.terrace = res.data.platform_name;
        _this8.toGround = res.data.adsense_id;
        _this8.DetailsPopUp = res.data.users;
      });
      this.details = true;
    },
    getUrlPath: function getUrlPath() {
      // 获取 iframe src 路径
      var url = window.location.href;
      url = url.replace('/myiframe', '');
      return url;
    },
    queryClick: function queryClick() {
      this.query.page = 1;
      var tId;
      if (this.extensionVal != '' || this.extensionVal.length > 0) {
        var share = [this.extensionVal].map(function (e) {
          return e[e.length - 1];
        }).toString(); // 最后一位ID
        tId = share == ',' ? '' : share;
      }
      this.getList(this.query.page, this.query.pagesize, tId);
    },
    batch: function batch() {
      var _this9 = this;
      getStructrueAndUser({
        project_ids: this.userId.toString()
      }).then(function (res) {
        _this9.personnelArrs = res.data;
        _this9.distribution = true;
      });
    },
    ReceiverMethod: function ReceiverMethod(val) {
      var _this10 = this;
      //数据接收人搜索
      if (val == '') {
        getUserList({
          get_type: '0',
          type: '0'
        }).then(function (res) {
          _this10.Receiver_Arr = res.data;
        });
      } else {
        getUserList({
          get_type: '0',
          type: '0',
          real_name: val
        }).then(function (res) {
          _this10.Receiver_Arr = res.data;
        });
      }
    },
    flowerArray: function flowerArray() {
      var _this11 = this;
      getUserList({
        get_type: '0',
        type: '0'
      }).then(function (res) {
        //数据接收人
        _this11.Receiver_Arr = res.data;
      });
      getPlatformList().then(function (res) {
        _this11.extension = res.data;
      });
      //花名
      agentFlowerNameList().then(function (res) {
        _this11.flower_Array = res.data;
      });
    },
    handleSizeChange: function handleSizeChange(val) {
      //每页 ${val} 条
      this.query.pagesize = val;
      this.getList(this.query.page, this.query.pagesize);
    },
    handleCurrentChange: function handleCurrentChange(val) {
      //当前页
      this.query.page = val;
      this.getList(this.query.page, this.query.pagesize);
    },
    checkSelectable: function checkSelectable(row) {
      if (row.disabled === false) {
        // console.log(1111111);
        return true;
      } else if (row.disabled === true) {
        // console.log(222222222);
        return false;
      }
    },
    handleSelectionChange: function handleSelectionChange(val) {
      //获取选中id
      var userId = [];
      val.forEach(function (item) {
        userId.push(item.project_id);
      });
      this.userId = userId;
      // if (userId.length == 1) {
      //     getCheckedByChooseId({
      //         all_id: this.talcleId.toString(),
      //         checked_id: userId.toString()
      //     }).then(res => {
      //         this.tableData.forEach(event => {
      //             res.data.forEach(item => {
      //                 if (item.id === event.project_id) {
      //                     event.disabled = item.disabled
      //                 }
      //             });
      //         });
      //     })
      // } else if (userId.length == 0) {
      //     this.tableData.forEach(event => {
      //         event.disabled = false
      //     });
      // }
    }
  }
};